import * as React from 'react'

// Types
import { IconSize } from '@/lib/enums'

function Play({
  color = 'currentColor',
  size = IconSize.Large,
  className
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M22 12L20.5 12.866L5.5 21.5263L4 22.3923L4 20.6602L4 3.33972L4 1.60767L5.5 2.47369L20.5 11.1339L22 12ZM18 12L6 5.07177L6 18.9282L18 12Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default React.memo(Play)
